<template>
  <b-card class="p-0 m-0">
    <div>
      <b-table
        :fields="fields"
        striped
        bordered
        responsive
        class="position-relative text-center patient-appoinment"
        hover
        :items="paginatedItems">
        <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="vs-checkbox-con"
            @change="row.toggleDetails">
          </b-form-checkbox>
        </template>
        <template #row-details="row">
          <b-tabs>
            <b-tab active title="Invoice" style="width: 75vw !important">
              <b-row>
                <b-col sm="12" md="12" lg="12">
                  <RecipePatient :AppointmentId="row.item.id" />
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Information appointment">
              <b-row>
                <b-col md="4" lg="6" cols="7">
                  <b-card class="text-center" v-if="row.item.office_visit.nurses">
                    <b-avatar
                      class="mb-1"
                      size="30"
                      :src="row.item.office_visit.nurses.avatar">
                    </b-avatar>
                    <div class="text-center d-flex flex-column">
                      <span class="font-weight-bold">NURSE</span>
                      <b-link
                        :to="{
                          name: 'admin-users-view',
                          params: { id: row.item.office_visit.nurses.id },
                        }">
                        <span>
                          {{ row.item.office_visit.nurses.name }}
                          {{ row.item.office_visit.nurses.lastname }}
                        </span>
                      </b-link>
                    </div>
                  </b-card>
                  <b-card title="Procedure Timeline">
                    <!-- <app-timeline>
                      <app-timeline-item
                        v-for="(procedure, i) in row.item.office_visit
                          .visit_status_auditor"
                        :key="i"
                        :variant="procedure.color"
                      >
                        <div
                          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between align-items-start mb-1 mb-sm-0"
                        >
                          <h6>{{ procedure.name }}</h6>
                          <small class="text-muted">{{
                            procedure.pivot.time
                          }}</small>
                        </div>
                      </app-timeline-item>
                    </app-timeline> -->
                  </b-card>
                </b-col>
                <b-col md="8" lg="6" cols="7">
                  <b-card class="text-center" v-if="row.item.office_visit.omas">
                    <b-avatar
                      class="mb-1"
                      size="30"
                      :src="row.item.office_visit.omas.avatar">
                    </b-avatar>
                    <div class="text-center d-flex flex-column">
                      <span class="font-weight-bold">OMA</span>
                      <b-link
                        :to="{
                          name: 'admin-users-view',
                          params: { id: row.item.office_visit.omas.id },
                        }">
                        <span>
                          {{ row.item.office_visit.omas.name }}
                          {{ row.item.office_visit.omas.lastname }}
                        </span>
                      </b-link>
                    </div>
                  </b-card>
                  <b-card title="Vital signs" class="card-transaction">
                    <b-row>
                      <b-col
                        lg="4"
                        md="3"
                        xl="3"
                        v-for="(item, i) in row.item.office_visit.type_vital_sign"
                        :key="i">
                        <!-- <statistic-card-vertical
                          icon="ActivityIcon"
                          :statistic="item.pivot.value"
                          :statistic-title="item.prefix"
                          color="info"
                        /> -->
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </template>

        <template #cell(type_visits)="data">
          <div class="text-nowrap">
            <b-badge
              :style="{ 'background-color': data.item.type_visits.color }"
              v-b-tooltip.hover.top="data.item.type_visits.name"
              class="badge-glow">
              <span class="align-text-top text-capitalize">{{
                data.item.type_visits.prefix
              }}</span>
            </b-badge>
          </div>
        </template>

        <template #cell(type_consultations)="data">
          <span>{{
            data.item.type_visits.name ? data.item.type_visits.name : 'Not specified'
          }}</span>
        </template>

        <template #cell(facilities)="data">
          <b-link
            :to="{
              name: 'admin-facilities-view',
              params: { id: data.item.facilities.id },
            }">
            <span>{{ data.item.facilities.name }}</span>
          </b-link>
        </template>

        <template #cell(frontdesks)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="data.item.frontdesks.avatar" />
            </template>
            <b-link
              :to="{
                name: 'admin-users-view',
                params: { id: data.item.frontdesks.id },
              }">
              <span
                class="font-weight-bold d-block text-nowrap d-flex flex-column align-items-start">
                <span>
                  {{ data.item.frontdesks.name }}
                </span>
                <span>
                  {{ data.item.frontdesks.lastname }}
                </span>
              </span>
            </b-link>
          </b-media>
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button
              v-if="data.item.office_visit.visit_statuses_id === 5"
              variant="outline-warning"
              class="btn-icon btn-circle border-0"
              v-b-tooltip.hover.top="'Refund'"
              @click="refundAppointment(data.item.id)">
              <i class="fas fa-exchange-alt"></i>
            </b-button>
            <b-button
              v-if="data.item.office_visit.omas && [1, 2, 3].includes(+userRol)"
              variant="outline-primary"
              class="btn-icon btn-circle border-0"
              v-b-tooltip.hover.top="'Change OMA'"
              @click="changeOMA(data.item)">
              <i class="fas fa-user-md"></i>
            </b-button>

            <b-button
              v-if="[1, 2, 3].includes(+userRol)"
              variant="outline-primary"
              class="btn-icon btn-circle border-0"
              v-b-tooltip.hover.top="'Change Facility'"
              @click="changeFacility(data.item)">
              <i class="far fa-hospital"></i>
            </b-button>
            <b-button
              v-if="[1, 6, 7].includes(+data.item.office_visit.visit_statuses_id)"
              variant="outline-danger"
              class="btn-icon btn-circle border-0"
              v-b-tooltip.hover.top="'Delete'"
              @click="deleteAppointment(data.item.id)">
              <i class="far fa-trash-alt"></i>
            </b-button>
          </div>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged" />
    </div>
    <ChangeFacility
      v-if="conditionFacilityAppointment"
      :appointmentById="appointmentById"
      @closeModalFacility="conditionFacilityAppointment = false"
      @NotificationCheck="NotificationCheck" />
    <RefundAppointment
      v-if="conditionRefund"
      :appointmentById="appointmentById"
      @closeModalPay="conditionRefund = false"
      @NotificationCheck="NotificationCheck" />
    <ChangeOma
      v-if="conditionOmaAppointment"
      :appointmentById="appointmentById"
      @closeModalFacility="conditionOmaAppointment = false"
      @NotificationCheck="NotificationCheck" />
    <ToastNotification ref="toast" />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
  BTabs,
  BTab,
  BFormCheckbox,
  BCol,
  BRow,
  BButton,
  BMedia,
  BAvatar,
  BBadge,
  BLink,
  VBTooltip,
} from 'bootstrap-vue'
/* import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue"; */
/* import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue"; */

import axiosA from '@/core/services/apiInsurance/appointment/actions'

import NotificationMixin from '@/mixins/NotificationMixin'
import PaginationTable from '@/components/PaginationTable'
import ToastNotification from '@/components/ToastNotification'
import ChangeFacility from '../actions/ChangeFacility.vue'
import ChangeOma from '../actions/ChangeOma.vue'
import RefundAppointment from '../actions/RefundAppointment'
import Swal from 'sweetalert2'
import RecipePatient from '@/view/pages/appointment/actions/RecipePatient.vue'
import axiosPA from '@/core/services/apiInsurance/patient/appointment'
export default {
  components: {
    BCard,
    // BCardHeader,
    // BCardTitle,
    BTable,
    BTabs,
    BTab,
    BFormCheckbox,
    BCol,
    BRow,
    BButton,
    BMedia,
    BAvatar,
    BBadge,
    BLink,
    /* AppTimeline,
    AppTimelineItem,
    StatisticCardVertical, */
    ToastNotification,
    ChangeFacility,
    ChangeOma,
    RefundAppointment,
    RecipePatient,
    PaginationTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    appointmentData: {
      type: String,
      required: true,
    },
  },
  mixins: [NotificationMixin],
  data() {
    return {
      fields: [
        { key: 'show_details', class: 'th-class-adjusted' },
        { key: 'id', sortable: true, class: 'th-class-adjusted' },
        {
          key: 'type_visits',
          label: 'Type Visit',
          sortable: true,
          class: 'th-class-adjusted',
        },
        {
          key: 'visit_date',
          label: 'Visit Date',
          sortable: true,
          class: 'th-class-adjusted',
        },
        {
          key: 'type_consultations',
          label: this.$t('Visit Reason'),
          sortable: true,
          class: 'th-class-adjusted',
        },
        {
          key: 'facilities',
          label: 'Facility',
          sortable: true,
          class: 'th-class-adjusted',
        },
        {
          key: 'frontdesks',
          label: 'Frontdesk',
          sortable: true,
          class: 'th-class-adjusted',
        },
        { key: 'actions', class: 'th-class-adjusted' },
      ],
      conditionFacilityAppointment: false,
      conditionOmaAppointment: false,
      conditionRefund: false,
      appointmentById: null,
      userRol: JSON.parse(localStorage.getItem('userData')).roles[0],
      currentPage: {
        page: 1,
      },
      perPage: 10,
      totalItems: 0,
      paginatedItems: [],
      totalPages: 0,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        const response = await axiosPA.appointmentListPage(this.appointmentData)
        const { data, total, from, current_page, to } = response.registro
        this.paginatedItems = data
        this.totalUsers = total
        this.dataMeta.of = total
        this.dataMeta.from = from
        this.dataMeta.to = to
        this.currentPage.page = current_page
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    },
    deleteAppointment(item) {
      const data = {
        id: item,
      }
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axiosA.appointmentDelete(data).then(res => {
            const { type, message } = NotificationMixin.methods.notificationCheck(res)
            this.NotificationCheck({ type, message })
            this.$emit('patientAppointment')
          })
        }
      })
    },
    changeOMA(item) {
      this.conditionOmaAppointment = true
      this.appointmentById = item
    },
    changeFacility(item) {
      this.conditionFacilityAppointment = true
      this.appointmentById = item
    },
    refundAppointment(item) {
      this.conditionRefund = true
      this.appointmentById = item
    },
    NotificationCheck({ type, message }) {
      this.$refs.toast[type](message)
      this.$emit('patientAppointment')
    },
    async pageChanged(page = 1) {
      const response = await axiosPA.appointmentListPage(this.appointmentData, page)
      const { data, total, from, current_page, to } = response.registro
      this.paginatedItems = data
      this.totalUsers = total
      this.dataMeta.of = total
      this.dataMeta.from = from
      this.dataMeta.to = to
      this.currentPage.page = current_page
    },
  },
}
</script>

<style lang="scss">
.th-class-adjusted {
  padding: 0.9rem 0.1rem !important;
}
</style>
