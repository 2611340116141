import { userAxios } from '../index'

const officeVisitList = async perPage => {
  try {
    return await userAxios
      .get(`insurance/officeVisits/all/${perPage}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const officeVisitListPagination = async (perPage, page) => {
  try {
    return await userAxios
      .get(`insurance/officeVisits/all/${perPage}?page=${page}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const verifyCupon = async data => {
  try {
    return await userAxios.post(`insurance/cupons/verify`, data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const verifyMembership = async id => {
  try {
    return await userAxios
      .get(`insurance/memberships/active/patient/${id}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const officeVisitFilter = async (perPage, data) => {
  try {
    return await userAxios
      .post(`insurance/officeVisits/search/${perPage}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const officeVisitFilterPagination = async (perPage, page, data) => {
  try {
    return await userAxios
      .post(`insurance/officeVisits/search/${perPage}?page=${page}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const officeVisitDetailt = async id => {
  try {
    return await userAxios.get(`insurance/officeVisits/${id}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const roomsAvailable = async () => {
  try {
    return await userAxios.get(`insurance/getAvailableRooms`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const roomsCheckAvailable = async (id, idRoom) => {
  try {
    return await userAxios
      .get(`insurance/officeVisits/assignRoom/${id}/${idRoom}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const procedureNurse = async (id, data) => {
  try {
    return await userAxios
      .post(`insurance/officeVisits/nurse/${id}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const procedureOma = async (id, data) => {
  try {
    return await userAxios
      .post(`insurance/officeVisits/oma/${id}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}
const assignToOma = async id => {
  try {
    return await userAxios
      .post(`insurance/officeVisits/takeVisit`, id)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const procedureCheck = async (id, data) => {
  try {
    return await userAxios
      .put(`insurance/officeVisits/allComplete/${id}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const procedurePay = async (id, data) => {
  try {
    return await userAxios
      .post(`insurance/officeVisits/payment/${id}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const procedurePayPartial = async (id, data) => {
  try {
    return await userAxios
      .post(`insurance/appointment/pending-pay/${id}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const updateReasonVisit = async (id, data) => {
  try {
    return await userAxios
      .post(`insurance/officeVisits/update/reason_visit/${id}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const dataForNurse = async id => {
  try {
    return await userAxios
      .get(`insurance/officeVisits/getDataNurse/${id}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const dataForOMA = async id => {
  try {
    return await userAxios
      .get(`insurance/officeVisits/getDataOma/${id}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  officeVisitList,
  officeVisitListPagination,
  officeVisitFilter,
  officeVisitFilterPagination,
  officeVisitDetailt,
  roomsAvailable,
  roomsCheckAvailable,
  procedureNurse,
  procedureOma,
  assignToOma,
  procedureCheck,
  procedurePay,
  procedurePayPartial,
  dataForNurse,
  dataForOMA,
  updateReasonVisit,
  verifyCupon,
  verifyMembership,
}
