import { userAxios } from '../../index'

const membershipsList = async perPage => {
  try {
    return await userAxios
      .get(`insurance/memberships/all/${perPage}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipParameterList = async perPage => {
  try {
    return await userAxios
      .get(`insurance/membership-parameters/all/${perPage}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipParameterTypesList = async () => {
  try {
    return await userAxios
      .get(`insurance/membership-parameters-types`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const mptParametrizables = async page => {
  try {
    return await userAxios
      .get(`insurance/membership-parameters-types/parametrizables?page=${page}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const typeParameterSearch = async (perPage = 10, data) => {
  try {
    return await userAxios
      .post(`insurance/membership-parameters-types/search/${perPage}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipParameterCreate = async data => {
  try {
    return await userAxios
      .post('insurance/membership-parameters', data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipsCreate = async data => {
  try {
    return await userAxios.post('insurance/memberships', data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipParameterUpdate = async (id, data) => {
  try {
    return await userAxios
      .put(`insurance/membership-parameters/${id}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipsUpdate = async (id, data) => {
  try {
    return await userAxios.put(`insurance/memberships/${id}`, data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipParameterDelete = async id => {
  try {
    return await userAxios
      .delete(`insurance/membership-parameters/${id}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipsDelete = async id => {
  try {
    return await userAxios.delete(`insurance/memberships/${id}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipsPerPatient = async id => {
  try {
    return await userAxios
      .get(`insurance/memberships/all/patient/${id}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipParameterPagination = async (perPage, id) => {
  try {
    return await userAxios
      .get(`insurance/membership-parameters/all/${perPage}?page=${id}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipsPagination = async (perPage, id) => {
  try {
    return await userAxios
      .get(`insurance/memberships/all/${perPage}?page=${id}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipParameterFilter = async (perPage, data) => {
  try {
    return await userAxios
      .post(`insurance/membership-parameters/search/${perPage}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipsFilter = async (perPage, data) => {
  try {
    return await userAxios
      .post(`insurance/memberships/search/${perPage}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipParameterFilterPagination = async (perPage, page, data) => {
  try {
    return await userAxios
      .post(`insurance/membership-parameters/search/${perPage}?page=${page}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipsFilterPagination = async (perPage, page, data) => {
  try {
    return await userAxios
      .post(`insurance/memberships/search/${perPage}?page=${page}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const membershipsAddPatienLots = async data => {
  try {
    return await userAxios
      .post(`insurance/memberships/scan_code`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  membershipParameterList,
  membershipParameterCreate,
  membershipParameterUpdate,
  membershipParameterDelete,
  membershipParameterPagination,
  membershipParameterFilter,
  membershipParameterFilterPagination,
  typeParameterSearch,
  membershipsList,
  membershipsDelete,
  membershipsPagination,
  membershipsFilterPagination,
  membershipsFilter,
  membershipsUpdate,
  membershipsCreate,
  membershipsPerPatient,
  membershipParameterTypesList,
  mptParametrizables,
  membershipsAddPatienLots,
}
