import { userAxios } from '../index'

const appointmentDelete = async id => {
  try {
    return await userAxios.post(`insurance/consultation/delete`, id).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const appointmentChangeFacility = async data => {
  try {
    return await userAxios
      .post(`insurance/consultation/changeClinic`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const appointmentChangeOMA = async data => {
  try {
    return await userAxios
      .post('insurance/consultation/changeOma', data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const appointmentAssignCuppon = async data => {
  try {
    return await userAxios.post(`insurance/validate_cupon`, data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const appointmentCancel = async (appoinment, data) => {
  try {
    return await userAxios
      .put(`insurance/officeVisits/cancel/${appoinment}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const appointmentRefund = async (appoinment, data) => {
  try {
    return await userAxios
      .post(`insurance/consultation/refund/${appoinment}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  appointmentDelete,
  appointmentChangeFacility,
  appointmentChangeOMA,
  appointmentAssignCuppon,
  appointmentCancel,
  appointmentRefund,
}
