<template>
  <b-card class="p-0 m-0">
    <div>
      <b-table :items="membershipData" :fields="fields" striped bordered responsive
        class="position-relative text-center patient-appoinment" hover>
        <template #cell(name)="data">
          <span>{{ data.item.memberships_parameters.name }}</span>
        </template>
        <template #cell(seller)="data">
          <span>{{ data.item.seller.name }} {{ data.item.seller.lastname }}</span>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button variant="flat-info" class="btn-icon" v-b-tooltip.hover.top="'Details appointment'"
              v-if="$can('store', 'appointment')" @click="openMembershipCheck(data.item)">
              <i class="far fa-bookmark"></i>
            </b-button>
            <b-button variant="flat-info" class="btn-icon" v-b-tooltip.hover.top="'Verify membership'"
              v-if="$can('store', 'appointment')" @click="openVerifyMembership(data.item)">
              <i class="fas fa-award"></i>
            </b-button>
          </div>
        </template>
      </b-table>
    </div>
    <ToastNotification ref="toast" />
    <detailsMembership :appointmentsData="appointmentsData" :verifyBool.sync="checkMembershipBool" />
    <verifyMembership :userId="userId" :verifyBool.sync="verifyMembershipBool" />
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BButton,
  VBTooltip,
} from "bootstrap-vue";

// import axiosA from "@/core/services/apiInsurance/membership/actions";

import NotificationMixin from "@/mixins/NotificationMixin";
import verifyMembership from "../verifyMembership.vue";

import ToastNotification from "@/components/ToastNotification";
import Swal from "sweetalert2";
import detailsMembership from "./detailsMembership.vue";

export default {
  components: {
    BCard,
    BTable,
    BButton,
    detailsMembership,
    verifyMembership,
    ToastNotification,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    membershipData: {
      type: Array,
      default: () => { },
      required: true,
    },
  },
  mixins: [NotificationMixin],
  data() {
    return {
      fields: [
        // { key: "show_details", class: "th-class-adjusted" },
        { key: "id", sortable: true, class: "th-class-adjusted" },
        { key: "name", sortable: true, class: "th-class-adjusted" },
        { key: "start_date", sortable: true, class: "th-class-adjusted" },
        { key: "end_date", sortable: true, class: "th-class-adjusted" },
        // { key: "parameters", sortable: true, class: "th-class-adjusted" },
        { key: "seller", sortable: true, class: "th-class-adjusted" },
        { key: "cash", sortable: true, class: "th-class-adjusted" },
        { key: "cc", sortable: true, class: "th-class-adjusted" },
        { key: "total", sortable: true, class: "th-class-adjusted" },
        { key: "actions", class: "th-class-adjusted" },
      ],
      appointmentsData: {},
      checkMembershipBool: false,
      verifyMembershipBool: false,
      userId: 0
    };
  },
  methods: {
    deletemembership(item) {
      const data = {
        id: item,
      };
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // axiosA.membershipDelete(data).then((res) => {
          //   const { type, message } =
          //     NotificationMixin.methods.notificationCheck(res);
          //   this.NotificationCheck({ type, message });
          //   this.$emit("patientMembership");
          // });
        }
      });
    },
    NotificationCheck({ type, message }) {
      this.$refs.toast[type](message);
      this.$emit("patientMembership");
    },
    openMembershipCheck(item) {
      this.appointmentsData = item;
      this.checkMembershipBool = true
    },
    openVerifyMembership(item) {
      this.userId = item.patient_id;
      this.verifyMembershipBool = true
    },
  },
};
</script>

<style lang="scss">
.th-class-adjusted {
  padding: 0.9rem 0.1rem !important;
}
</style>
