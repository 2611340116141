import { userAxios } from '../index'

const appointmentList = async perPage => {
  try {
    return await userAxios
      .get(`insurance/consultation/all/${perPage}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const appointmentDetail = async id => {
  try {
    return await userAxios.get(`insurance/consultation/${id}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const appointmentCreate = async data => {
  try {
    return await userAxios.post('insurance/consultation', data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const appointmentUpdate = async (id, data) => {
  try {
    return await userAxios.put(`insurance/consultation/${id}`, data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const appointmentDelete = async id => {
  try {
    return await userAxios.delete(`insurance/consultation/${id}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const appointmentPagination = async (perPage, page) => {
  try {
    return await userAxios
      .get(`insurance/consultation/all/${perPage}?page=${page}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const appointmentFilter = async (perPage, data) => {
  try {
    return await userAxios
      .post(`insurance/officeVisits/search/${perPage}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const appointmentFilterPagination = async (perPage, page, data) => {
  try {
    return await userAxios
      .post(`insurance/officeVisits/search/${perPage}?page=${page}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  appointmentList,
  appointmentDetail,
  appointmentCreate,
  appointmentUpdate,
  appointmentDelete,
  appointmentPagination,
  appointmentFilter,
  appointmentFilterPagination,
}
