import { userAxios } from '../index'

const appointmentList = async id => {
  try {
    return await userAxios
      .get(`insurance/users/patients/consultations/${id}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const appointmentListPage = async (id, page = 1) => {
  try {
    const response = await userAxios.get(
      `insurance/users/patients/consultations/${id}?page=${page}`
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const patientTreatmentConsent = async (id, data) => {
  try {
    return await userAxios
      .post(`insurance/office_vist/informed_consent/signature/${id}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  appointmentList,
  patientTreatmentConsent,
  appointmentListPage,
}
