<template>
    <div>
        <b-modal id="add-new-patient-sidebar" :visible="verifyBool" bg-variant="white" shadow backdrop no-header
            @change="(val) => changeStateSidebar(val)" size="xl">
            <template #default="{ hide }">
                <div class="d-flex justify-content-between align-items-center content-sidebar-header mt-4 px-2 py-1">
                    <h5 class="mb-0">
                        Verify Membership
                    </h5>

                    <!-- <i class="fas fa-times ml-1 cursor-pointer" size="16" @click="hide"></i> -->
                </div>

                <div class="p-2 card-container">
                    <div v-for="(membership, index) in paginatedInfo" :key="index" class="campaign-info">
                        <h4 class="info-title">Membership: {{ membership.campaign }}</h4>
                        <div class="info-details">
                            <p>Dates: {{ membership.dates }}</p>
                            <p>Id: {{ membership.id_membership }}</p>
                            <p>Consultations:</p>
                            <ul class="list-unstyled my-2 facility">
                                <li v-for="(consult, i) in membership.consultations" :key="i">
                                    <b-badge variant="primary">
                                        <span class="text-white badge-wrapper">
                                            Count: {{ consult.count }} -
                                            Activation Date: {{ consult.activation_date }} -
                                            Count Used: {{ consult.count_used }} -
                                            Count Available: {{ consult.count_available }}
                                        </span>
                                    </b-badge>
                                </li>
                            </ul>
                            <p>Diagnostic Imagings:</p>
                            <ul class="list-unstyled my-2 facility">
                                <li v-for="(diagnostic, i) in membership.diagnostic_imagings" :key="i">
                                    <b-badge class="badge-wrapper" variant="primary">
                                        <span class="text-white">
                                            {{ diagnostic.name }} -
                                            {{ diagnostic.activation_date }} -
                                            Count Used: {{ diagnostic.count_used }} -
                                            Count Available: {{ diagnostic.count_available }}
                                        </span>
                                    </b-badge>
                                </li>
                            </ul>
                            <p>Laboratories:</p>
                            <ul class="list-unstyled my-2 facility">
                                <li v-for="(laboratories, i) in membership.laboratories" :key="i">
                                    <b-badge class="badge-wrapper" variant="primary">
                                        <span class="text-white">
                                            {{ laboratories.name }} -
                                            {{ laboratories.activation_date }} -
                                            Count Used: {{ laboratories.count_used }} -
                                            Count Available: {{ laboratories.count_available }}
                                        </span>
                                    </b-badge>
                                </li>
                            </ul>
                            <p>Laboratories Pack:</p>
                            <ul class="list-unstyled my-2 facility">
                                <li v-for="(laboratories_pack, i) in membership.laboratories_pack" :key="i">
                                    <b-badge class="badge-wrapper" variant="primary">
                                        <span class="text-white">
                                            {{ laboratories_pack.name }} -
                                            {{ laboratories_pack.activation_date }} -
                                            Count Used: {{ laboratories_pack.count_used }} -
                                            Count Available: {{ laboratories_pack.count_available }}
                                        </span>
                                    </b-badge>
                                </li>
                            </ul>
                            <p>Procedures:</p>
                            <ul class="list-unstyled my-2 facility">
                                <li v-for="(procedures, i) in membership.procedures" :key="i">
                                    <b-badge class="badge-wrapper" variant="primary">
                                        <span class="text-white">
                                            {{ procedures.name }} -
                                            {{ procedures.activation_date }} -
                                            Count Used: {{ procedures.count_used }} -
                                            Count Available: {{ procedures.count_available }}
                                        </span>
                                    </b-badge>
                                </li>
                            </ul>
                            <p>Tests:</p>
                            <ul class="list-unstyled my-2 facility">
                                <li v-for="(tests, i) in membership.tests" :key="i">
                                    <b-badge class="badge-wrapper" variant="primary">
                                        <span class="text-white">
                                            {{ tests.name }} -
                                            {{ tests.activation_date }} -
                                            Count Used: {{ tests.count_used }} -
                                            Count Available: {{ tests.count_available }}
                                        </span>
                                    </b-badge>
                                </li>
                            </ul>
                            <p>Treatments:</p>
                            <ul class="list-unstyled my-2 facility">
                                <li v-for="(treatments, i) in membership.treatments" :key="i">
                                    <b-badge class="badge-wrapper" variant="primary">
                                        <span class="text-white">
                                            {{ treatments.name }} -
                                            {{ treatments.activation_date }} -
                                            Count Used: {{ treatments.count_used }} -
                                            Count Available: {{ treatments.count_available }}
                                        </span>
                                    </b-badge>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalMemberships"
                    :per-page="itemsPerPage"
                    aria-controls="example-table"
                    @input="onPageChange"
                ></b-pagination>
            </template>
        </b-modal>
        <ToastNotification ref="toast" />
    </div>
</template>

<script>
import { BModal, BBadge, BPagination } from "bootstrap-vue";
import { ref, computed, watch } from "@vue/composition-api";
import ToastNotification from "@/components/ToastNotification";
import axios from "@/core/services/apiInsurance/office-visit/index";

export default {
    components: {
        BModal,
        ToastNotification,
        BBadge,
        BPagination
    },
    model: {
        prop: "verifyBool",
        event: "update:verifyBool",
    },
    props: {
        verifyBool: {
            type: Boolean,
            required: true,
        },
        userId: {
            type: Number,
            default: 0,
            required: false,
        }
    },
    setup(props, { emit }) {
        const toast = ref(null);
        const info = ref([]);
        const currentPage = ref(1);
        const itemsPerPage = ref(2);

        const paginatedInfo = computed(() => {
            const start = (currentPage.value - 1) * itemsPerPage.value;
            const end = start + itemsPerPage.value;
            return info.value.slice(start, end);
        });

        const totalMemberships = computed(() => {
            return info.value.length;
        });

        watch(() => props.verifyBool, () => {
            info.value = [];
            if (props.verifyBool == true) getInfo(props.userId);
        });

        function getInfo(id) {
            axios.verifyMembership(id).then(({ data }) => {
                membershipVerified(data);
            }).catch((e) => {
                console.log(e);
                // toast.value.danger("Error, membership not found or expired!");
            })
        }

        const membershipVerified = (membershipsData) => {
            membershipsData.forEach(mem => {
                function organizeData(property) {
                    const newArray = []
                    mem.memberships_parameters[property].forEach(({ activation_date,
                        name,
                        count_used,
                        count_available }) => {
                        newArray.push({
                            activation_date,
                            name,
                            count_used,
                            count_available
                        })
                    })
                    return newArray
                }
                const consultations = [],
                    diagnostic_imagings = organizeData("diagnostic_imagings"),
                    laboratories = organizeData("laboratories"),
                    laboratories_pack = organizeData("laboratories_pack"),
                    procedures = organizeData("procedures"),
                    tests = organizeData("tests"),
                    treatments = organizeData("treatments")
                mem.memberships_parameters.consultations.forEach(({ activation_date,
                    count,
                    count_used,
                    count_available }) => {
                    consultations.push({
                        activation_date,
                        count,
                        count_used,
                        count_available
                    })
                })
                info.value.push({
                    campaign: mem.memberships_parameters.name,
                    dates: `${mem.start_date} - ${mem.end_date}`,
                    id_membership: mem.id,
                    consultations,
                    diagnostic_imagings,
                    laboratories,
                    laboratories_pack,
                    procedures,
                    tests,
                    treatments,
                })
            });
        }

        const changeStateSidebar = (val) => {
            emit('update:verifyBool', val)
        }

        const onPageChange = (page) => {
            currentPage.value = page;
        };

        return {
            changeStateSidebar,
            getInfo,
            info,
            currentPage,
            itemsPerPage,
            paginatedInfo,
            totalMemberships,
            onPageChange,
        };
    },
};
</script>

<style lang="scss" scoped>
.card-container {
    display: flex;
    flex-direction: row; /* Cambiado de row a column */
    align-items: center; /* Centra el contenido horizontalmente */
    justify-content: space-evenly;
    margin: 16px;
}

.campaign-info {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    max-width: 100%; /* Cambiado de 400px a 100% */
    margin-bottom: 1rem; /* Espacio entre las tarjetas */
}

.info-title {
    color: #3498db;
    font-size: 1.5em;
    margin-bottom: 10px;
}

.info-details p {
    margin: 5px 0;
}

.badge-wrapper {
    overflow: auto;
    white-space: pre-wrap;
}
</style>
