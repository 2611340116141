<template>
  <b-modal
    id="modal-lg"
    v-model="conditionOma"
    hide-footer
    hide-header
    centered
    no-close-on-backdrop
    size="lg"
    @hide="onHide"
  >
    <div class="text-center">
      <b-avatar
        v-if="dataOma"
        size="100px"
        :src="dataOma.avatar"
      />
      <b-avatar
        v-else
        size="100px"
        src="https://media.istockphoto.com/vectors/profile-picture-vector-illustration-vector-id587805156?k=20&m=587805156&s=612x612&w=0&h=Ok_jDFC5J1NgH20plEgbQZ46XheiAF8sVUKPvocne6Y="
      />
      <h4 class="mt-1">{{dataOma.name}} {{dataOma.lastname}}</h4>
    </div>
    <form @submit.prevent="onSubmitOma">
      <validation-provider #default="{ errors }" name="OMA" rules="required">
        <b-form-group label="OMA" label-for="OMA">
          <treeselect
            id="OMA"
            v-model="dataOma"
            :options="optionsFacility"
            :normalizer="normalizer"
            value-format="object"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
      <div class="d-flex justify-content-end mt-2">
        <b-button
          type="button"
          class="mr-2"
          variant="outline-danger"
          @click="onHide"
        >
          {{ $t("Cancel") }}
        </b-button>
        <b-button variant="primary" type="submit" :disabled="loading">
          <span v-if="!loading">Change</span>
          <span v-else>
            <SpinnerLoading />
          </span>
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { BButton, BModal, BFormGroup, BAvatar } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import SpinnerLoading from "@/components/SpinnerLoading";
import NotificationMixin from "@/mixins/NotificationMixin";

import axiosF from "@/core/services/apiInsurance/admin/user";
import axiosA from "@/core/services/apiInsurance/appointment/actions";
export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BAvatar,

    ValidationProvider,
    ValidationObserver,

    SpinnerLoading,
  },
  data() {
    return {
      conditionOma: true,
      dataOma: this.appointmentById?.office_visit?.omas,
      optionsFacility: [],
      loading: false,
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  props: {
    appointmentById: {
      type: Object,
      required: true,
    },
  },
  mixins: [NotificationMixin],
  mounted() {
    this.getFacilities();
  },
  methods: {
    onHide() {
      this.$emit("closeModalFacility", false);
    },
    getFacilities() {
      const data = {
        role: 'MEDICAL-ASSISTANT'
      }
      axiosF.userFilter(10, data).then(({ registro: {data} }) => {
        this.optionsFacility = data;
      });
    },
    onSubmitOma() {
      this.loading = true;
      const data = {
        OmaId: this.dataOma.id,
        AppointmentId: this.appointmentById?.id,
      };
      axiosA.appointmentChangeOMA(data).then((res) => {
        this.loading = false;
        const { type, message } =
          NotificationMixin.methods.notificationCheck(res);
        this.$emit("NotificationCheck", { type, message });
        this.onHide();
      });
    },
  },
};
</script>

<style lang="scss">
</style>