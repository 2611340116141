import { userAxios } from '../index'

const patientList = async perPage => {
  try {
    return await userAxios
      .get(`insurance/users/patients/all/${perPage}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const patientDetail = async id => {
  try {
    return await userAxios.get(`insurance/users/patients/${id}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const patientCreate = async data => {
  try {
    return await userAxios.post('insurance/users/patients', data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const patientUpdate = async (id, data) => {
  try {
    return await userAxios
      .put(`insurance/users/patients/${id}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const patientDelete = async id => {
  try {
    return await userAxios.delete(`insurance/users/patients/${id}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const patientPagination = async (perPage, paginate) => {
  try {
    return await userAxios
      .get(`insurance/users/patients/all/${perPage}?page=${paginate}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const patientFilter = async (perPage, data) => {
  try {
    return await userAxios
      .post(`insurance/users/patients/search/${perPage}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const patientFilterPagination = async (perPage, paginate, data) => {
  try {
    return await userAxios
      .post(`insurance/users/patients/search/${perPage}?page=${paginate}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const referencePagination = async (perPage, paginate) => {
  try {
    return await userAxios
      .get(`insurance/references/all/${perPage}?page=${paginate}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const referenceFilter = async (perPage, data) => {
  try {
    return await userAxios
      .post(`insurance/references/search/${perPage}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  patientList,
  patientDetail,
  patientCreate,
  patientUpdate,
  patientDelete,
  patientPagination,
  patientFilter,
  patientFilterPagination,
  referencePagination,
  referenceFilter,
}
